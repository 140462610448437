import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import React, { Fragment,useState,useEffect } from 'react';
import {  } from 'react-router-dom';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import EventBus from "eventing-bus";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {getSingleVoting,getVotingsItems,updateVoting,updateVotingItems, updateVotingItemImage,setLoader} from "../../store/actions/Auth";


import './index.css';

const AddNewVoting = props => {

  const [state, setState] = useState({exchanges: [{ 
    image:"",
    previewImage:"",
    title:"",
    description:""
  }]});

  const [status, setStatus] = useState("Active");
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [itemTitle, setItemTitle] = useState()
  const [itemDescription, setItemDescription] = useState()
  const [totalVotes, setTotalVotes] = useState(0)
  const [allVotes, setAllVotes] = useState(0)
  const [image, setImage] = useState()
  const [previewImage, setPreviewImage] = useState()
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modelData,setModelData] = useState();

  const toggle = () => setModalIsOpen(!modelOpen);
  const modelOpen =(data)=>{
    setModalIsOpen(true)
    setModelData(data)
    setItemTitle(data.title);
    setTotalVotes(data.totalVotes)
    setAllVotes(data.totalVotes);
    setItemDescription(data.description);
  }
  

  const handleSubmit = async () => {
      if(!title) return EventBus.publish('error', "Please set title");
      if(!description) return EventBus.publish('error', "Please set description");
      props.setLoader({message:"Voting updating...",status:true});
      props.updateVoting({title,description,status,votingId:props.match.params.id})
  };

  const handleSubmitItem = async (votingItemId) => {
    if(!itemTitle) return EventBus.publish('error', "Please set item title");
    if(!itemDescription) return EventBus.publish('error', "Please set item description");
    if(!totalVotes) return EventBus.publish('error', "Please set votes");
    if(totalVotes < allVotes) return EventBus.publish('error', "You can only add more vote");
    props.setLoader({message:"Voting item updating...",status:true});
    props.updateVotingItems({title:itemTitle,description:itemDescription,votingItemId,votingId:props.match.params.id,totalVotes})
  };

  const handleImageChange = (event,votingItemId) => {
    const files = event.target.files;
    if (!files[0]) return EventBus.publish('error', "Please set item image");
    const reader = new FileReader();
    reader.onload = e => {
      const imageUrl = e.target.result;
      setImage(files[0]);
      setPreviewImage(imageUrl);
      props.setLoader({message:"Voting item image updating...",status:true});
      props.updateVotingItemImage({image:files[0],previewImage:imageUrl,votingItemId,votingId:props.match.params.id})
    };
    reader.readAsDataURL(files[0]);
  };

  useEffect(()=>{
      props.setLoader({message:"Get voting detail...",status:true});
      props.getSingleVoting({_id:props.match.params.id})
      props.getVotingsItems({_id:props.match.params.id})
  },[])

  useEffect(()=>{
      if(Object.keys(props.singleVoting).length > 0) {
      let {status,title,description} = props.singleVoting;
        setStatus(status)
        setTitle(title)
        setDescription(description)
      }
      if(props.setVotingItems) {
        setItemTitle()
        setItemDescription()
        setTotalVotes(0)
        toggle();
      }
  },[props.singleVoting,props.setVotingItems])

  return (
    <div className='content'>
      <h1>Edit Voting Pole</h1>
      <div className='main-container mintedNFT'>
        <Fragment>
          <div className='add-voting-page'>
          <div className='row'>
            <div className='col-12'>
              <ValidatorForm className='form'>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label htmlFor='PoleTitle'>Pole Title</label>
                    <TextField
                      id='PoleTitle'
                      name='PoleTitle'
                      className='form-control'
                      placeholder='Pole title'
                      value={title}
                      onChange={e=>setTitle(e.target.value)}
                      validators={['required']}
                      errorMessages={['Pole Title is required']}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='Description'>Description</label>
                    <TextField
                      id='Description'
                      name='Description'
                      multiline
                      className='form-control'
                      placeholder='Pole Description'
                      value={description}
                      onChange={e=>setDescription(e.target.value)}
                      validators={['required']}
                      errorMessages={['Pole Description is required']}
                    />
                  </div>
                  <div className='form-group'>
                      <label htmlFor='category'>Status</label>
                      <Autocomplete
                            value={status}
                            onChange={(event, newValue) => {setStatus(newValue)}}
                            id='controllable-states-demo'
                            options={['Inactive', 'Active']}
                            sx={{ width: 300 }}
                            renderInput={params => <TextField {...params} />}
                          />
                  </div>
                  <div className='col-12'>
                    <div className='form-group text-center submit-group'>
                    {props.isLoader.status
                      ? <button className='btn-style-one'><i className="fa fa-spinner fa-spin fa-fw"></i></button>
                      : <button className='btn-style-one' onClick={handleSubmit}>Update Pole</button>
                    }
                    </div>
                  </div>
                  <hr></hr>
                  {props.setVotingItems && props.setVotingItems.length > 0 &&
                      <div className='form-group'>
                        <div className='row'>
                        {props.setVotingItems.map((item,index)=>
                            <div className='col-lg-3 col-md-6 col-sm-12'>
                              <div className='voting-item' onClick={()=>modelOpen(item)}>
                                  <div className='img-box'>
                                    <img
                                      src={item['image']}
                                      alt=''
                                    />
                                  </div>
                                  <div className='text-box'>
                                    <h3>{item['title']}</h3>
                                    <p>{item['description'].substring(0, 30)}</p>
                                    <p>Total Votes : {item['totalVotes']}</p>
                                  </div>
                                </div>
                              </div>
                        )}
                        </div>
                      </div>
                  }
                </div>
              </div>
            </ValidatorForm>
            </div>
          </div>
          </div>
        </Fragment>
      </div>

          {/* ---------------ADD Media MODAL--------------- */}

      <Modal
        isOpen={modalIsOpen} toggle={toggle}
        className='main-modal add-media-modal'
      >
        <ModalHeader
          toggle={toggle}
          x
        >
        </ModalHeader>
        <ModalBody className='modal-body'>
          <div className='title-area'>
            <h2>Update Item</h2>
          </div>
          <ValidatorForm className='form'>
            <div className='row'>
              <div className='col-12'>
                
                <div className='form-group'>
                  <div className='img-upload-area'>
                    <div className='img-box'>
                      {modelData && modelData.image &&
                      <img
                        src={modelData.image ? modelData.image : ""}
                        alt=''
                      />
                      }
                    </div>
                    <div className='text-box'>
                      <i className='icon'><img src={require('../../assets/img/upload.png')} alt=''/></i>
                      <p>Drag and drop or Browse your image file<br /> (png and jpg file only)</p>
                      <div className='input-file'>
                        <input
                          type='file'
                          id='image'
                          name='image'
                          accept='image/*'
                          onChange={(e)=>handleImageChange(e,modelData && modelData._id)}
                          className='form-control'
                          // required={!Boolean(state.selectedRack)}
                        />
                        <span className='add-btn'>Select Image</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label htmlFor='name'>Item Name</label>
                  <TextField
                    id='name'
                    name='name'
                    defaultValue={itemTitle ? itemTitle : ""}
                    className='form-control'
                    placeholder='Title'
                    onChange={e=>setItemTitle(e.target.value)}
                    validators={['required']}
                    errorMessages={['Name is required']}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='name'>Description:</label>
                  <TextField
                    id='description'
                    name='description'
                    multiline
                    defaultValue={itemDescription ? itemDescription : ""}
                    className='form-control'
                    onChange={e=>setItemDescription(e.target.value)}
                    validators={['required']}
                    errorMessages={['Description is required']}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='name'>Total Votes:</label>
                  <TextField
                    id='totalVotes'
                    name='totalVotes'
                    type='number'
                    InputProps={{ minLength: totalVotes }}
                    defaultValue={totalVotes ? totalVotes : 0}
                    className='form-control'
                    onChange={e=>setTotalVotes(e.target.value)}
                    validators={['required']}
                    errorMessages={['Total votes is required']}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group text-center'>
                  <button className='btn-style-one' onClick={()=>handleSubmitItem(modelData && modelData._id)}>Update</button>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </ModalBody>
      </Modal>

    </div>
  );
};


const mapDispatchToProps = {getSingleVoting,updateVoting,getVotingsItems,updateVotingItems,updateVotingItemImage,setLoader};

const mapStateToProps = ({ Auth }) => {
  let {singleVoting,setVotingItems,isLoader} = Auth;
  return {singleVoting,setVotingItems,isLoader};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewVoting);
