import { PURGE } from 'redux-persist';
import { setToken } from '../axios';

var initialState = {
  auth: localStorage.getItem('token'),
  isLogin: false,
  isLoader: { message: "", status: false },
  singleVoting: {},
  getVoting: [],
  setVotingItems: [],
  mediaItems: [],
  saleItems: [],
  soldItems: [],
  votingChoices: []
};

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== ADMIN REDUCERS ============= */

    case 'SET_LOGIN_DATA':
      setToken(payload.token);
      localStorage.setItem(
        'token',
        payload.token
      );
      return {
        ...state,
        auth: payload.token,
        user: payload.user
      };

    case 'LOGIN_LOADER':
      return {
        ...state,
        isLogin: payload,
        isLoader: payload
      };

    case 'LOGOUT':
      window.localStorage.removeItem('token');
      return {
        ...state,
        address: '',
        auth: null
      };

    /*========== LOADER REDUCERS ============= */

    case 'SET_LOADER':
      return {
        ...state,
        isLoader: payload
      };

    case 'GET_VOTING':
      return {
        ...state,
        getVoting: payload
      };

    case 'SET_SINGLE_VOTING':
      return {
        ...state,
        singleVoting: payload
      };

    case 'SET_VOTING_ITEMS':
      return {
        ...state,
        setVotingItems: payload
      }

    case 'SET_VOTING_CHOICES':
      return {
        ...state,
        votingChoices: payload
      }

    /*========== MEDIA REDUCERS ============= */

    case 'SET_MEDIA_ITEMS':
      return {
        ...state,
        mediaItems: payload
      };

    /*========== SALE REDUCERS ============= */

    case 'SET_SALE_ITEMS':
      return {
        ...state,
        saleItems: payload
      };

    case 'SET_SOLD_ITEMS':
      return {
        ...state,
        soldItems: payload
      };

    default:
      return state;
  }
};

export default Auth;
