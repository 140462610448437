/* -- set app title --*/
const AppTitle = 'ADMIN Figure Ouch';

/* -- set app mode -- */
const AppMode = ['production'];
// const AppMode = [''];

/* -- set API URLs --*/
const testing = 'http://localhost:4000';
// const production = 'https://server.figureouch.com';
// const development = 'https://server.figureouch.com';
const production = 'https://dev.server.figureouch.com';
const development = 'https://dev.server.figureouch.com';
// const production = 'http://localhost:4000';
// const development = 'http://localhost:4000';

let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';
// let env = 'production', networkId = '', message = '', explorer = '';
switch (AppMode[0]) {
  case 'development':
    networkId = 4;
    message = 'Please switch your network to Rinkeby testnet';
    SocketUrl = development;
    explorer = 'https://metamart.metawarriors.world'
    break;
  case 'production':
    networkId = 1;
    SocketUrl = production;
    message = 'Please switch your network to Ethereum Mainnet';
    explorer = 'https://metamart.metawarriors.world'
    break;
  case 'testing':
    networkId = 4;
    SocketUrl = testing;
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    break;
  default:
    networkId = 4;
    SocketUrl = 'http://localhost:4006';
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
}

let ApiUrl = `${SocketUrl}/v1`;

const colors = [
  "#0074D9",
  "#2ECC40",
  "#FF4136",
  "#B10DC9",
  "#FF851B",
  "#39CCCC",
  "#FFDC00",
  "#F012BE",
  "#AAAAAA",
  "#85144B",
  "#3D9970",
  "#FF7F50",
  "#8A2BE2",
  "#228B22",
  "#DC143C",
  "#FF1493",
  "#4169E1",
  "#8B008B",
  "#20B2AA",
  "#000000",
]

export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, env, colors };