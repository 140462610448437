import moment from 'moment';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import React, { Fragment, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { toast } from 'react-toastify';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SubmitButton from '../../components/Button';
import { setLoader, getSoldItems } from "../../store/actions/Auth";

import './index.css';

const alternate = require('../../assets/img/place-img.png');

const Rack = props => {

  let [saleData, setSaleData] = useState([]);

  useEffect(() => {
    props.setLoader({ message: "Fetching Data...", status: true });
    props.getSoldItems();
  }, []);

  useEffect(() => {
    if (props.soldItems && props.soldItems.length > 0) {
      setSaleData(props.soldItems);
    }
  }, [props.soldItems]);

  const handleImageError = (idx) => {
    // Set the failed image to the fallback image
    saleData[idx]['itemImage'] = alternate;
    setSaleData([...saleData]);
  };

  const handleCopyText = (item) => {
    navigator.clipboard.writeText(item)
      .then(() => EventBus.publish("success", "Beneficiary address copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  const handleCopyInstagram = (item) => {
    navigator.clipboard.writeText(item)
      .then(() => EventBus.publish("success", "Instagram account address copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  const callFilter = (value) => {
    let filterValue = saleData.filter((a) => {
      if (value) {
        return a.itemName.toString().includes(value)
      }
      else {
        return a
      }
    });
    setSaleData(filterValue);
  }

  return (
    <div className='content'>
      <h1>Dashboard</h1>
      <div className='main-container mintedNFT'>
        <div className='main-container-head mb-3'>
          <p className='main-container-heading'>Recent Sales  List</p>
          {/* <button className='see-all-btn'>
            See all
          </button> */}
          <div className="search-form">
            <input type="text" placeholder="Search By Item Name" onChange={e => callFilter(e.target.value)} />
            <button className="search-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2393 17.6735L13.1756 13.6098C10.0061 16.1079 5.43903 15.7029 2.7585 12.6862C0.0780783 9.66951 0.213117 5.08641 3.06662 2.2328C5.92013 -0.620799 10.5033 -0.75603 13.52 1.92436C16.5368 4.60474 16.9419 9.17191 14.444 12.3414L18.5076 16.4061C18.7711 16.6242 18.8883 16.9728 18.8101 17.3058C18.732 17.6388 18.472 17.8989 18.139 17.977C17.8059 18.0552 17.4573 17.938 17.2393 17.6744V17.6735ZM2.62577 7.62186C2.62577 9.96438 4.02883 12.08 6.18757 12.9918C8.3462 13.9036 10.841 13.4344 12.5209 11.8006C12.5387 11.7801 12.5566 11.7603 12.5746 11.7414C12.5926 11.7225 12.6133 11.7045 12.6331 11.6883C14.4667 9.80425 14.8125 6.92612 13.4775 4.66129C12.1426 2.39647 9.45697 1.30529 6.9206 1.99717C4.38424 2.68905 2.62478 4.99282 2.62467 7.62183L2.62577 7.62186Z" fill="#231916" />
              </svg>
            </button>
          </div>
        </div>

        <Fragment>
          <div className='main-container-head mb-3'>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Time Stamp</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile No</TableCell>
                    <TableCell>DOB</TableCell>
                    <TableCell>Instagram</TableCell>
                    <TableCell>Address</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    saleData.map((item, idx) => (
                      <>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">{moment.utc(new Date(item['createdAt']).getTime()).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</TableCell>
                          <TableCell>
                            <img key={idx} src={item['itemImage'] ? item['itemImage'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                          </TableCell>
                          <TableCell>{item['itemName']}</TableCell>
                          <TableCell style={{ fontWeight: '600' }}>{item['itemPrice']} $</TableCell>
                          <TableCell>{item['userName']}</TableCell>
                          <TableCell><a className='email-area' href={`mailto:${item['userEmail']}`}>{item['userEmail']}</a></TableCell>
                          {/* <TableCell>{item['phone'] ? item['phone'] : item['userId']['phone'] ? item['userId']['phone'] : "-"}</TableCell>
                          <TableCell>{item['DOB'] ? moment(item['DOB']).format("DD/MM/YYYY") : item['userId']['DOB'] ?  moment(item['userId']['DOB']).format("DD/MM/YYYY") : "-"}</TableCell> */}
                          {/* <TableCell>{item['instagram'] ? item['instagram'].substring(0, 15) + '...' : item['userId']['instagram'] ? item['userId']['instagram'].substring(0, 15) + '...' : "-"} <button className="icon-edit" onClick={() => handleCopyInstagram(item['instagram'] ? item['instagram'] : item['userId']['instagram'])}><img src={require('../../assets/img/edit.svg')} alt='' /></button></TableCell>
                          <TableCell>{item['userAddress'] ? item['userAddress'].substring(0, 15) + '...' : item['userId']['address'] ? item['userId']['address'].substring(0, 15) + '...' : "-"} <button className="icon-edit" onClick={() => handleCopyText(item['userAddress'] ? item['userAddress'] : item['userId']['address'])}><img src={require('../../assets/img/edit.svg')} alt='' /></button></TableCell> */}
                          {/* <TableCell>
                      <button className='editand-remove'>
                        <img src={require('../../assets/img/pencil.svg')} alt='' />
                      </button>
                      <button className='editand-remove'>
                        <img src={require('../../assets/img/trash.svg')} alt='' />
                      </button>
                    </TableCell> */}
                        </TableRow >
                      </>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setLoader,
  getSoldItems
};

const mapStateToProps = ({ Auth }) => {
  let { isLoader, soldItems } = Auth;
  return { isLoader, soldItems };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rack);
