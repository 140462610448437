import Sales from './views/Sales/index.js';
import Voting from './views/Voting/index.js';
import Dashboard from './views/Dashboard/index.js';
import Media from './views/Media/index.js';
import AddNewVoting from './views/AddNewVoting/index.js';
import UserChoice from './views/VotingChoice/index.js';
import EditVoting from './views/EditVoting/index.js';


export const routes = [
  {
    layout: '/home',
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    // icon: "<svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none"> <path d="M11.2731 22.5458H6.26289C4.87938 22.5458 3.75781 21.4243 3.75781 20.0407V7.51533C3.75781 6.13182 4.87938 5.01025 6.26289 5.01025H21.2934C22.6769 5.01025 23.7985 6.13182 23.7985 7.51534V10.0204" stroke="#231916" stroke-width="2.50508"/><path d="M5.00977 10.02H25.0504" stroke="#231916" stroke-width="2.50508"/><path d="M5.00977 16.2832H11.2725" stroke="#231916" stroke-width="2.50508"/><path d="M14.1759 20.6969C13.6377 19.8675 13.6377 18.7991 14.1759 17.9697L14.9376 16.7958L15.5733 15.5492C16.0225 14.6684 16.9478 14.1342 17.9352 14.1856L19.3327 14.2583L20.7302 14.1856C21.7176 14.1342 22.6428 14.6684 23.092 15.5492L23.7278 16.7958L24.4895 17.9697C25.0277 18.7991 25.0277 19.8675 24.4895 20.6969L23.7278 21.8708L23.092 23.1175C22.6428 23.9983 21.7176 24.5325 20.7302 24.4811L19.3327 24.4083L17.9352 24.4811C16.9478 24.5325 16.0225 23.9983 15.5733 23.1175L14.9376 21.8708L14.1759 20.6969Z" stroke="#231916" stroke-width="2.50508"/><circle cx="19.3333" cy="19.3333" r="1.20833" fill="#231916"/></svg>"
  },
  {
    layout: '/home',
    path: '/',
    component: Dashboard,
    hidden: true
  },
  {
    layout: '/home',
    path: '/userChoice',
    name: 'User Choice',
    component: UserChoice,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/media',
    name: 'Media',
    component: Media,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/sales',
    name: 'Sales',
    component: Sales,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/voting',
    name: 'Voting',
    component: Voting,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/addnewvoting',
    name: 'Add New Voting',
    component: AddNewVoting,
    hidden: true,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/editvoting/:id',
    name: 'Edit Voting',
    component: EditVoting,
    hidden: true,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/logout',
    name: 'Logout',
    // component: Voting,
    hidden: true,
    icon: 'tim-icons icon-plus-circle'
  }
];

export const admin_routes = [
  {
    layout: '/home',
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/',
    component: Dashboard,
    hidden: true
  },
  {
    layout: '/home',
    path: '/userChoice',
    name: 'UserChoice',
    component: UserChoice,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/Media',
    name: 'Media',
    component: Media,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/sales',
    name: 'Sales',
    component: Sales,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/addnewvoting',
    name: 'Add New Voting',
    component: AddNewVoting,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/editvoting',
    name: 'Edit Voting',
    component: EditVoting,
    icon: 'tim-icons icon-plus-circle'
  },
  {
    layout: '/home',
    path: '/voting',
    name: 'Voting',
    component: Voting,
    icon: 'tim-icons icon-plus-circle'
  }
];
// export const routes = admin_routes

